/* NotFoundPage.css */

.cont-404 {
  text-align: center;
  margin-bottom: 40px;
}

.cont-404 img {
  margin-top: 10%;
  width: 80%; /* Adjust size as needed */
  max-width: 500px; /* Limit maximum width */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Add spacing below the image */
}

.cont-404 a {
  color: blue; /* Change link color */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Make link text bold */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .cont-404 img {
    width: 100%; /* Adjust size to fill the container */
    max-width: none; /* Remove maximum width limit */
    margin-top: 20px; /* Adjust margin for smaller screens */
  }
}
