/* General Styling */

.columns {
  display: grid;
  grid-gap: 20px;
  margin: 20px auto;
  height: max-content;
}

.column {
  padding: 0 15px;
}

.classic-card {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.classic-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.card-title {
  font-size: 22px;
  font-weight: 700;
  color: #333;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-icon {
  margin-right: 10px;
  color: #007BFF;
}

.card-content {
  margin-top: 15px;
}

.card-items {
  color: #555;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
  margin: 10px 0;
  transition: color 0.3s ease;
}

.card-items:hover {
  color: #007BFF;
}

.arrow-icon {
  margin-right: 8px;
  color: #333;
}

ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

/* Slideshow */
.slide-container {
  margin: 20px auto;
  width: 90%;
  max-width: 1200px;
}

.slide {
  height: 300px;
  background-size: cover;
  background-position: center;
}

/* Home Description */
.home-description {
  width: 80%;
  margin: 40px auto;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-size: 18px;
}

/* Responsive Design */
@media only screen and (max-width: 768px) {
  .columns {
    grid-template-columns: 1fr;
  }

  .home-description {
    font-size: 16px;
  }
}

@media only screen and (min-width: 769px) {
  .columns {
    grid-template-columns: repeat(2, 1fr);
  }

  .home-description {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1024px) {
  .columns {
    grid-template-columns: repeat(3, 1fr);
  }

  .home-description {
    font-size: 20px;
  }
}
