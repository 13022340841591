/* MCQPage.css */

.mcq-page {
  margin-top: 20px;
 
}

.question-container {
  display: flex;
  flex-direction: column;
}

.question {
  margin-bottom: 20px;
  border: 2px solid #c3fb73;
  padding: 10px;
  border-radius: 5px;
  background-color:azure ;
}

.question p {
  margin-bottom: 10px;
}

.options {
  list-style: none;
  padding: 0;
}

.options li {
  margin-bottom: 5px;
  cursor: pointer;
}

.options li:hover {
  background-color: #f0f0f0;
}

.answer {
  font-weight: bold;
  margin-top: 10px;
  color: green;
}
.btm{
  background-color: blue;
  color: white;
  border-radius: 10px;
}
