/* QuizCategory.css */


/* Container styling */
.container-fluid {
    padding: 20px; /* Add padding to the container for spacing */
    margin: 0 auto; /* Center the container horizontally */
    max-width: max-content; /* Limit the maximum width of the container */
    margin-top: 20px;
    text-align: center;
  }
  
  /* Navbar styling */
  .navbar {
    background-color: #007bff; /* Change the background color of the navbar */
    color: #fff; /* Change the text color of the navbar */
    padding: 10px 0; /* Add padding to the top and bottom of the navbar */
  }
  
  .navbar-brand {
    font-size: 24px; /* Adjust the font size of the navbar brand */
  }
  
  /* Card items styling */
  .card-items {
    text-decoration: none;
    color: #333;
    max-width: max-content;
    transition: color 0.3s ease; /* Add transition effect for color change */
  }
  
  .card-items:hover {
    color: #007bff; /* Change the text color on hover */
  }
  
  /* Folder icon styling */
  .folder-icon {
    margin-right: 5px; /* Add space between the icon and text */
    color: #ffc107; /* Change the color of the folder icon */
  }
  
  /* Column spacing */
  .col-6 {
    width: calc(50% - 20px); /* Each column takes up half of the container width with spacing */
    margin-bottom: 20px; /* Add margin at the bottom of each column for spacing */
  }
  
  .py-2 {
    padding-top: 10px; /* Increase top padding */
    padding-bottom: 10px; /* Increase bottom padding */
  }
  
  .para{
    margin-left: 10%;
    width: 80%;
    border: greenyellow solid 2px;
    background-color: aliceblue;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 30px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .col-6 {
      width: 100%; /* Columns take up full width on smaller screens */
    }
  }
  