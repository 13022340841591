body {
  background-color: #f0f4f8; /* Light gray background for contrast */
  font-family: Arial, sans-serif; /* Modern font */
}

.navbars {
  overflow: hidden;
  width: 100%;
  background-color: #4a90e2; /* Bright blue for the navbar background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px; /* Padding for spacing */
}

.logo {
  color: #ffffff; /* White logo text for contrast */
  font-size: 30px;
  font-weight: bold; /* Bold text for emphasis */
}

.links {
  display: flex;
  margin-left: 20px;
}

.links a {
  font-size: 16px; /* Font size for links */
  color: #ffffff; /* White text color for links */
  text-decoration: none;
  padding: 10px 15px; /* Padding for links */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

/* Add hover effects for links */
.links a:hover {
  background-color: #f39c12; /* Orange background on hover */
  color: #ffffff; /* Keep text white on hover */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .navbar-container {
    flex-direction: column; /* Stack items vertically on small screens */
  }

  .links {
    margin-top: 10px; /* Add margin between logo and links */
  }
}
