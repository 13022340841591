.sidebar {
  background-color: #f4f4f4;
  padding: 20px;
  height: fit-content;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 200px;
}

.category {
  margin-bottom: 20px;
  position: relative; /* Ensure relative positioning for dropdown icon */
}

.category-title {
  font-weight: bold;
  font-size: 1.2em;
  color: #333;
}
.category a:hover{
  color: red;
}
.subcategory a {
  display: block;
  margin-top: 5px;
  color: #555;
  text-decoration: none;
  transition: color 0.3s ease;
}

.subcategory a:hover {
  color: #007bff;
}

.dropdown-icon {
  font-size: 1.2em;
  color: #777;
  cursor: pointer;
  position: absolute; /* Position dropdown icon relative to category */
  right: 10px; /* Adjust right position as needed */
  top: 50%; /* Align dropdown icon vertically */
  transform: translateY(-50%); /* Center dropdown icon vertically */
}

.subcategory {
  display: none;
  margin-left: 20px;
}

.subcategory.expanded {
  display: block;
}
