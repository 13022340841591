/* Footer container styling */
.footer {
  background-color: #333;  /* Dark background for the footer */
  color: #fff;  /* White text color */
  padding: 20px 0;  /* Vertical padding */
  font-family: 'Arial', sans-serif;  /* Clean font choice */
  text-align: center;  /* Center all text content */
}

/* Container for the footer links */
.footer-container {
  display: flex;
  justify-content: center;  /* Center horizontally */
  align-items: center;
  margin-bottom: 20px;  /* Space between the links and the bottom copyright */
}

.footer-column {
  text-align: center;  /* Center the text in columns */
}

/* Style for each list within the footer */
.footer-column ul {
  list-style: none;  /* Remove bullet points */
  padding: 0;
}

.footer-column ul li {
  margin: 5px 0;  /* Space between each link */
}

/* Styling for the links */
.footer-column ul li a {
  color: #fff;  /* White text for links */
  text-decoration: none;  /* Remove underline */
  font-size: 16px;
  transition: color 0.3s ease;  /* Smooth transition on hover */
}

.footer-column ul li a:hover {
  color: #00bcd4;  /* Change link color on hover */
}

/* Footer bottom section with copyright */
.footer-bottom {
  border-top: 1px solid #555;  /* Light border for separation */
  padding-top: 10px;  /* Space above the text */
}

.footer-bottom p {
  font-size: 14px;  /* Smaller font for copyright */
  margin: 0;  /* Remove extra margins */
}

/* Responsive design for smaller devices */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;  /* Stack links vertically */
    text-align: center;
  }

  .footer-column {
    margin-bottom: 15px;
  }
}
