/* main.css */

/* Main container styles */
.main-container {
    display: flex;
    justify-content: center;
    padding: 5px;
  }
  
  /* Sidebar container styles */
  .sidebar-container {
    flex: 0.1;
    transition: transform 0.3s ease;
    transform: translateX(-100%);
  }
  
  /* Show sidebar when visible class is added */
  .sidebar-container.show-menu {
    transform: translateX(0);
  }
  
  /* MCQ container styles */
 
  /* Toggle button styles */
  .menu-icon {
    display: none;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 999;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
  }

  /* ContentPage.css */

.navigation-links {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    margin-top: 20px;
    margin-bottom: 20px;

    
  }
  
  .navigation-links a {
    display: inline-block;
    padding: 10px 15px;
    text-decoration: none;
    color: #fff;
    background-color: #007bff;
    border: 1px solid #007bff;
    border-radius: 100%;
    transition: background-color 0.3s ease;
  }
  
  .navigation-links a:hover {
    background-color: #0056b3;  
  }
  .navigation-links a:first-child {
    margin-right: 10px; /* Add margin to the right of the "Previous" button */
  }
  
  .navigation-links a:last-child {
    margin-left: 10px; /* Add margin to the left of the "Next" button */
  }
  .para{
    margin-left: 10%;
    width: 80%;
    border: greenyellow solid 2px;
    background-color: aliceblue;
    padding: 10px;
    border-radius: 10px;
  }
  
  /* Media query for responsiveness */
  @media screen and (max-width: 768px) {
    .menu-icon {
      display: block;
    }
  
    /* Show menu when toggle button is clicked */
    .sidebar-container {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 70%;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
  
    /* Remove margin to make full width */
   
  }
  